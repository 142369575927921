import moment from 'moment';

export class CalendarEvent {
    iCalUID: string
    summary: string
    start: string
    end: string
    description: string
    attendees: string[]

    constructor(event: any) {
        this.iCalUID = event.iCalUID
        this.summary = event.summary
        this.start = event.start
        this.end = event.end
        this.description = event.description
        this.attendees = event.attendees
    }


    getDateHoursString(type: 'start' | 'end') {
        return `${this.getDateString(type)} ${this.getHoursString(type)}`
    }

    getDateString(type: 'start' | 'end') {
        let date = (type === 'start' ? this.start : this.end);
        return moment(date).format('DD.MM.YYYY');
    }

    getHoursString(type: 'start' | 'end') {
        let date = (type === 'start' ? this.start : this.end);
        return moment(date).format('HH:mm');
    }

    public getPosition() {
        let start = new Date(this.start)
        let end = new Date(this.end)
        let difMS = Math.abs(end.getTime() - start.getTime())
        let difM = difMS / (1000 * 60)
        const height = Math.abs(difM * 100 / 60)
        const startM = start.getMinutes()
        const top = 100 * (start.getHours() - 7) + (startM === 30 ? height : 0 )

        return {
            height,
            top
        }
    }

}